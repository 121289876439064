<template>
  <div>
    <div class="vp-p-6" v-if="isLegacy">
      <VpAlertCard :emoji="null" class="vp-bg-orange-50 vp-w-full">
        <div class="vp-mt-[-8px]">
          <h4 class="vp-text-2xl font-semibold">Legacy Plan</h4>
          <p class="text-base font-medium">
            ({{ planName.split(" ")[0] }} ${{ price
            }}<span class="vp-text-grey-500">/{{ duration }}</span
            >)
          </p>
          <p class="vp-text-base vp-text-black font-normal">
            Switch to New Plans for enabling the Pro Features from Roles &
            Permission Section.
          </p>
          <a
            class="vp-font-normal vp-text-sm vp-underline vp-text-accent-700 vp-cursor-pointer"
            @click="$vayu.modal.open('current-pricing')"
            >Click here to view Legacy Plan pricing.
          </a>
        </div>
      </VpAlertCard>
    </div>

    <VpLoaderContainer loading v-if="loading"></VpLoaderContainer>
    <div v-else class="subscription">
      <portal to="header">
        <VyButton
          v-if="isActive && provider != 'appsumo'"
          :icon="$options.icons.Close"
          label="Cancel My Subscription"
          :disabled="!canChange"
          @click.native="checkPermission('ACCOUNT', 'delete', cancel)"
          class="button--danger button--muted button--sm button--rounded"
        />
      </portal>

      <template v-if="provider != 'appsumo'">
        <DifferentChannel class="vp-p-6" />
        <CancellationPending class="vp-p-6" />
      </template>

      <div v-if="offer" class="vp-bg-gray-200 vp-p-6">
        <img :src="offer" class="vp-w-full" />
      </div>

      <div class="vp-grid vp-bg-gray-100 vp-border-y vp-grid-cols-1">
        <div class="vp-grid vp-grid-cols-2 vp-gap-px">
          <div class="vp-p-6 vp-bg-white vp-flex vp-items-center">
            <div>
              <p class="vp-text-base vp-font-bold vp-leading-none">
                {{ planName }}
              </p>

              <p class="vp-text-gray-500 vp-text-sm vp-mt-2">Your Plan</p>
            </div>
          </div>

          <div class="vp-p-6 vp-bg-white vp-flex vp-items-center">
            <div>
              <p class="vp-text-base vp-font-bold vp-leading-none">Save 16%</p>

              <p class="vp-text-gray-500 vp-text-sm vp-mt-2">
                Switch to Annual Plan
              </p>
            </div>
          </div>

          <!-- PRO CARDS -->
          <template v-if="isActive">
            <div class="vp-p-6 vp-bg-white vp-flex vp-items-center">
              <div>
                <p class="vp-text-base vp-font-bold vp-leading-none">
                  <VpDatetime :value="validity" />
                </p>

                <p class="vp-text-gray-500 vp-text-sm vp-mt-2">Next Renewal</p>
              </div>
            </div>

            <div class="vp-p-6 vp-bg-white vp-flex vp-items-center">
              <div>
                <p class="vp-text-base vp-font-bold vp-leading-none">
                  {{ isLegacy ? `$${price}` : `$${campaignCount * price}` }}
                </p>

                <p class="vp-text-gray-500 vp-text-sm vp-mt-2">
                  {{ renewalChargesDec }}
                </p>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
    <CurrentPricing></CurrentPricing>
  </div>
</template>

<script>
import { Close } from "icons/icons.js";
import { mapGetters } from "vuex";

import subscription from "./subscription/mixin.js";
import CurrentPricing from "./CurrentPricing.vue";

export default {
  icons: {
    Close,
  },
  mixins: [subscription],
  components: {
    CurrentPricing,
  },
  computed: {
    ...mapGetters({
      checkPermission: "user/checkPermission",
    }),

    renewalChargesDec() {
      return this.isLegacy
        ? "Expected Renewal Charges"
        : `Expected Renewal Charges (${this.campaignCount} Users at $${this.price}/User)`;
    },
  },
  methods: {
    cancel() {
      this.$router.push({ name: "cancel-subscription" });
      this.$track("Subscription Cancel Clicked");
    },
  },
};
</script>
