<template>
  <ValidationProvider slim :name="name" :rules="rules">
    <VyField v-bind="$attrs" :name="name" :label="label">
      <slot />
    </VyField>
  </ValidationProvider>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    label: String,
    rules: [String, Object],
    name: String,
  },
};
</script>
