<template>
  <div>
    <h2 class="vp-text-3xl vp-font-semibold vp-mt-[6px]">Hello {{ name }},</h2>
    <p class="vp-text-base vp-text-gray-500">
      Welcome to Vepaar! Start your journey by setting up Store or CRM
    </p>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters({
      name: "user/name",
    }),
  },
};
</script>
