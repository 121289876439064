<template>
  <div class="cropper">
    <VpModal
      :name="`crop-modal--${id}`"
      title="Crop Image"
      _class="vp-w-1/2"
      @save="save"
      @reset="reset"
      @close="cancel()"
      @open="open($event)"
      :reset="cropperConfig && cropperConfig.force != true"
      :save-label="saveLabel"
      reset-label="Don't Crop"
      :saving="uploading"
    >
      <div class="vp-h-[450px] vp-p-4" v-if="img">
        <VueCropper
          class="vp-h-full"
          :background="false"
          ref="cropper"
          v-bind="cropperConfig"
          :src="img"
          alt="Source Image"
        />
      </div>

      <template #footer-note>
        <div class="vp-space-x-1">
          <Button
            :icon="$options.icons.RotateRight"
            @click.native="$refs.cropper.rotate(45)"
            v-tooltip="'Rotate 45 Degrees'"
          />

          <Button
            :icon="$options.icons.FitWidth"
            @click.native="fit('width')"
            v-tooltip="'Fit to Width'"
          />
          <Button
            :icon="$options.icons.FitHeight"
            @click.native="fit('height')"
            v-tooltip="'Fit to Height'"
          />
          <Button
            :icon="$options.icons.Reset"
            @click.native="$refs.cropper.reset()"
            v-tooltip="'Reset'"
          />
        </div>
        <p class="vp-mt-2 vp-text-gray-500 vp-text-sm">
          Use scroll to zoom-in & zoom-out image.
        </p>
      </template>
    </VpModal>
  </div>
</template>
<script>
import { FitHeight, FitWidth, Reset, RotateRight } from "icons/icons.js";

import { fileToBase64 } from "plugins/utils.js";

import Button from "./button-action.vue";

export default {
  icons: {
    RotateRight,
    Reset,
    FitWidth,
    FitHeight,
  },

  props: {
    id: {
      type: String,
      required: true,
    },
    upload: {
      type: Function,
    },
    saveLabel: {
      type: String,
      default: "Crop",
    },
  },
  components: {
    Button,
  },
  data() {
    return {
      img: null,
      imgId: null,
      imgType: null,
      imgName: null,
      cropperConfig: null,
      uploading: false,
    };
  },

  methods: {
    async open({ id, img, cropperConfig = {} }) {
      if (!img) {
        return;
      }
      if (typeof img != "string") {
        this.imgType = img[0].type;
        const { dataUrl, fileName } = await fileToBase64(img[0]);
        this.imgName = fileName;
        img = dataUrl;
      }
      this.$set(this, "cropperConfig", {
        autoCrop: true,
        autoCropArea: 1,
        ...cropperConfig,
      });
      this.img = img;
      this.imgId = id;
      if (!cropperConfig.force) {
        this.update(img);
      }
    },
    update(img) {
      let payload;
      payload = {
        value: img,
        id: this.imgId,
        config: this.cropperConfig,
        fileName: this.imgName,
      };
      this.$emit("input", payload);
    },
    cancel() {
      this.close();
      //This is required when user cancels the crop operation.
      //When user cancels the crop, we need to remove the selected image.
      this.$emit("cancel", this.imgId);
    },
    close() {
      this.img = this.imgId = null;
      this.$refs.cropper.reset();
    },
    async save() {
      const cropped = this.$refs.cropper
        .getCroppedCanvas({
          fillColor: "#fff",
        })
        .toDataURL(this.imgType);

      if (this.upload) {
        this.uploading = true;
        try {
          const res = await this.upload({
            dataUrl: cropped,
            fileName: this.imgName,
          });
          this.$emit("success", res);
          this.uploading = false;
        } catch (err) {
          this.$vayu.notify({
            title: "Failed to Upload Image",
            message:
              "Please try with diffrent image or try smaller size of image file.",
            state: "danger",
            duration: 4000,
          });
        }
        this.uploading = false;
      } else {
        this.update(cropped);
      }

      this.$vayu.modal.close(`crop-modal--${this.id}`);
      this.close();
    },

    fit(dimention) {
      this.$refs.cropper.reset();
      const cropper = this.$refs.cropper;
      const container = cropper.getContainerData();
      const canvas = cropper.getCanvasData();
      const image = cropper.getImageData();
      const cropBox = cropper.getCropBoxData();

      // Moves an image
      cropper.moveTo(
        (container.width - image.width) / 2,
        (container.height - image.height) / 2
      );

      // Sets the zoom
      let zoomToValue;
      if (dimention == "height") {
        zoomToValue = cropBox.height / canvas.naturalHeight;
      } else {
        zoomToValue = cropBox.width / canvas.naturalWidth;
      }
      cropper.zoomTo(zoomToValue, {
        x: container.width / 2,
        y: container.height / 2,
      });

      cropper.setCropBoxData({
        left: (container.width - cropBox.width) / 2,
        top: (container.height - cropBox.height) / 2,
      });
    },

    reset() {
      this.update(this.img);
      this.$vayu.modal.close(`crop-modal--${this.id}`);
      this.close();
    },
  },
};
</script>
