<template>
  <div class="vp-flex vp-items-center">
    <div class="vp-cursor-pointer" @click="openFilePicker">
      <slot :value="localValue || value"> </slot>
    </div>

    <input
      class="vp-opacity-0 vp-absolute vp-w-0 vp-h-0"
      :id="id"
      type="file"
      onclick="this.value = null"
      @change="onFileSelect($event)"
      accept=".jpg,.jpeg,.png"
    />

    <div class="vp-ml-4 vp-space-x-1">
      <div class="vp-relative vp-inline-block">
        <VyButton
          class="button button--muted button--gray button--circle button--md"
          :icon="$options.icons.Edit"
          @click.native="openFilePicker"
        />
      </div>
      <VyButton
        v-if="removable"
        class="button button--muted button--danger button--circle button--md"
        :icon="$options.icons.Delete"
        @click.native="onDeleteFile"
      />
    </div>

    <Cropper :id="id" @input="onCrop($event)" />
  </div>
</template>

<script>
import { Delete, Edit } from "icons/icons.js";

import Cropper from "./cropper/index.vue";
import { fileToBase64 } from "plugins/utils.js";

export default {
  icons: {
    Edit,
    Delete,
  },

  components: {
    Cropper,
  },

  props: {
    id: {
      type: String,
      required: true,
    },
    value: [Object, File],
    cropRatio: {
      type: Number,
      default: 1,
    },
    crop: Boolean,
    maxFileSize: {
      type: Number,
      default: 2 * 1024 * 1024, //2 MB
    },
    removable: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      localValue: null,
      localFileName: null,
      localFileType: null,
    };
  },

  created() {
    this.localValue = this.value;
  },

  methods: {
    openFilePicker() {
      document.querySelector(`#${this.id}`).click();
    },

    async onFileSelect(e) {
      const file = e.target.files[0];

      if (file.size > this.maxFileSize) {
        alert(
          "File size is larger than " +
            this.maxFileSize / 1024 / 1024 +
            "MB. Please select a smaller file."
        );
        return;
      }

      this.localFileName = file.name;
      this.localFileType = file.type;

      const base64 = await fileToBase64(file);

      if (this.crop) {
        this.openCropper(base64.dataUrl);
      } else {
        this.setValue(base64.fileName, base64.dataUrl);
      }
    },

    openCropper(base64) {
      this.$vayu.modal.open(`crop-modal--${this.id}`, {
        id: this.id,
        img: base64,
        cropperConfig: {
          force: true,
          aspectRatio: this.cropRatio || 1,
        },
      });
    },

    onCrop(data) {
      this.setValue(data.fileName, data.value);
    },

    setValue(name, url) {
      //OLD VALUE
      this.$emit("beforeChange", { ...this.localValue });

      // TODO :
      // When changing the value, the file should be deleted.
      // Currently it is being only deleted while user explicitly deletes the file.

      this.localValue = {
        status: "CHANGE",
        name: this.localFileName,
        type: this.localFileType,
        url,
        // size: (file.size / 1024 / 1024).toFixed(2) + "MB",
      };

      this.$emit("input", this.localValue);
    },

    onDeleteFile() {
      //OLD VALUE

      this.$emit("delete", { ...this.localValue });

      this.localValue = {
        status: "DELETE",
      };

      this.$emit("input", this.localValue);
    },
  },
};
</script>
