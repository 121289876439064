import Vue from "vue";
import campaignMobiles from "graph/mobileOptions.gql";
import countryQuery from "graph/countries.gql";
import menuQuery from "graph/menus.gql";
import createMediaQuery from "graph/createMedia.gql";
import deleteMediaQuery from "graph/deleteMedia.gql";
import currencyQuery from "graph/currencies.gql";
import productCategory from "graph/productCategory.gql";
import timezoneQuery from "graph/timezones.gql";
const app = new Vue();

export const currencyOptions = () => {
  return app
    .$query(currencyQuery)
    .then(({ data }) => data.currencies.data)
    .then((res) => {
      return res.map((item) => {
        let label = `${item.currency} - ${item.abbreviation}`;
        if (item.symbol) label += " - " + item.symbol;
        return {
          label: label,
          value: item.abbreviation,
        };
      });
    });
};

export const countryOptions = () => {
  return app
    .$query(countryQuery)
    .then(({ data }) => data.countries.data)
    .then((res) => {
      return res.map((item) => {
        return {
          label: item.name,
          value: item.id,
        };
      });
    });
};

export const timezoneOptions = () => {
  return app
    .$queryPublic(timezoneQuery)
    .then(({ data }) => data.timezones.data)
    .then((res) => {
      return res.map((item) => {
        return {
          label: item.name,
          value: item.id,
        };
      });
    });
};

export const productCategoryOptions = () => {
  return app
    .$query(productCategory, {
      perPage: -1,
    })
    .then(({ data }) => data.productCategories.data)
    .then((res) => {
      return res.map((item) => {
        return {
          label: item.name,
          value: item.id,
        };
      });
    });
};

export const callingCodes = () => {
  return app
    .$query(countryQuery)
    .then(({ data }) => data.countries.data)
    .then((res) => {
      return res.map((item, index) => {
        return {
          label: `${item.name} — ${item.callingCode}`,
          value: item.callingCode,
          id: `${item.callingCode}-${index}`,
        };
      });
    });
};

export const getMobileNumbers = () => {
  return app
    .$query(campaignMobiles)
    .then(({ data }) => data.campaignMobiles.data);
};

export const createMedia = (mediaFor, mediaList, fileList) => {
  if (!mediaList.length) {
    return new Promise((resolve) => resolve([]));
  }

  return app
    .$mutate(createMediaQuery, {
      for: mediaFor,
      base64s: mediaList,
      name: fileList,
    })
    .then(({ data }) => data.createMedia);
};

export const deleteMedia = (mediaIds) => {
  if (!mediaIds || (Array.isArray(mediaIds) && !mediaIds.length)) {
    return new Promise((resolve) => resolve(true));
  }

  return app
    .$mutate(deleteMediaQuery, {
      ids: mediaIds,
    })
    .then(({ data }) => data.deleteMedia);
};

export const getMenus = () => {
  return app.$query(menuQuery).then(({ data }) =>
    data.menus.data.map((menu) => ({
      value: menu.id,
      label: menu.name,
    }))
  );
};
