<template>
  <!-- BREADCRUMBS -->
  <!-- TODO: Add tooltips for custom labels -->
  <div
    class="vp-flex vp-items-center"
    :class="{ 'breadcrumbs--back': back && backRoute }"
    v-if="$route.meta.breadcrumbs"
  >
    <!-- BACK BUTTON -->

    <VyButton
      v-if="back && backRoute"
      class="button--square button--lg button--gray"
      :icon="$options.icons.ArrowLeft"
      @click.native="historyBack()"
    />
    <button
      v-else
      @click="$router.push('/')"
      class="vp-w-12 vp-h-12 vp-flex vp-justify-center vp-items-center vp-border-r"
    >
      <VpIcon
        class="vp-w-6 vp-h-6 vp-text-gray-500"
        :name="$options.icons.Home"
      />
    </button>

    <!-- ROUTES -->
    <div class="vp-space-x-2 vp-flex vp-items-center">
      <div
        :key="`breadcrumbs--${index}`"
        :class="{ 'vp-pl-3': !back || !backRoute }"
        v-for="(route, index) in breadcrumbs"
      >
        <span v-if="index != 0" class="vp-mr-2 vp-text-gray-300">/</span>
        <span
          v-if="index == breadcrumbs.length - 1"
          class="vp-text-sm vp-text-gray-500"
          >{{ routeLabel(route) }}</span
        >
        <router-link
          v-else
          :to="{ name: route.name, params: params[route.name] }"
          class="vp-text-sm vp-text-gray-500 hover:vp-text-primary-500"
          >{{ routeLabel(route) }}</router-link
        >
      </div>
    </div>
  </div>
</template>
<script>
import { ArrowLeft, Home } from "icons/icons.js";
import { truncate } from "lodash-es";

export default {
  name: "breadcrumbs",
  icons: {
    ArrowLeft,
    Home,
  },
  props: {
    truncate: {
      type: Number,
      default: 15,
    },
    back: {
      type: Boolean,
      default: true,
    },
    icon: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      //If a route name is added to this and has a value,
      // This value will be used instead of provided in router meta
      labels: {},
      params: {},
      routes: null,
    };
  },

  watch: {
    //When the route changes, if the new route's breadcrumbs does not contain the
    // the keys defined in labels, remove it.
    $route: {
      deep: true,
      handler(newValue) {
        for (var key in this.labels) {
          if (!newValue.meta.breadcrumbs.includes(key)) {
            delete this.labels[key];
          }
        }
      },
    },
  },

  created() {
    //Update the label of route
    this.$root.$on("breadcrumbs-label", (key, value) => {
      this.$set(this.labels, key, value);
    });

    //Update the params of route
    this.$root.$on("breadcrumbs-params", (key, value) => {
      this.$set(this.params, key, value);
    });

    this.routes = this.findRoutes();
  },

  computed: {
    breadcrumbs() {
      let breadcrumbs = this.$route.meta.breadcrumbs
        .map((item) => {
          //Gets the router object with the name.
          //Using manual list of routes instead of resolve method.
          //Resolve creates an issue when 'redirect' is used.
          //It returs the redirected route hence breaking the chain of breadcrumbs
          // const resolvedRoute = this.$router.resolve({ name: item });
          const resolvedRoute =
            this.routes && this.routes.find((route) => route.name == item);
          if (resolvedRoute) {
            const { name, meta } = resolvedRoute;
            return { name, meta };
          } else {
            return null;
          }
        })
        .filter((item) => item); //Remove non-existing routes
      return breadcrumbs;
    },

    backRoute() {
      const breadcrumbs = this.$route.meta && this.$route.meta.breadcrumbs;
      if (breadcrumbs && breadcrumbs.length > 1) {
        return breadcrumbs[breadcrumbs.length - 2];
      } else {
        return null;
      }
    },
  },

  methods: {
    historyBack() {
      window.history.back();
    },

    findRoutes() {
      const breadcrumbsRoutes = [];
      const filterRoutes = (routes) => {
        routes.forEach((route) => {
          if (route.meta && route.meta.breadcrumbs) {
            breadcrumbsRoutes.push(route);
          }
          if (route.children) {
            filterRoutes(route.children);
          }
        });
      };
      filterRoutes(this.$router.options.routes);
      return breadcrumbsRoutes;
    },
    routeLabel(route) {
      return (
        truncate(this.labels[route.name], { length: this.truncate }) ||
        route.meta.label
      );
    },
  },
};
</script>
