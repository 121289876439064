<template>
  <span
    class="vp-whitespace-nowrap vp-flex-nowrap vp-inline-flex vp-items-center vp-space-x-1"
  >
    <span v-if="isValueNegetive">-</span>
    <span v-if="hasSymbol && type == 'flat'">{{ symbol }}</span>
    <span>{{ localValue }}</span>
    <span v-if="hasSymbol && type == 'percentage'">%</span>
  </span>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    value: Number,
    type: {
      type: String,
      default: "flat", //flat, percentage
    },
    hasSymbol: {
      type: Boolean,
      default: true,
    },
    isNegetive: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      decimals: "store/currencyDecimals",
      symbol: "store/currencySymbol",
    }),

    isInt() {
      return this.value % 1 == 0;
    },

    isValueNegetive() {
      return this.isNegetive || this.value < 0;
    },

    localValue() {
      if (!this.value) return 0;
      return new Intl.NumberFormat("en", {
        maximumFractionDigits: this.decimals,
        minimumFractionDigits: this.isInt ? 0 : this.decimals,
      }).format(Math.abs(this.value) || 0);
    },
  },
};
</script>
